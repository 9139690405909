export const API_HOST = import.meta.env.VITE_API_URL;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

export const PASSWORD_REGEX = /^(?=.*[!@#$%^&*()\-_+={}[\]\\|/<>.,;])/;
export const CYRILLIC_REGEX = /[а-яА-ЯёЁ]/g;
export const NUMBER_REGEX = /^(?=.*\d)/;
export const INTEGER_REGEX = /^[0-9]\d*$/;
export const UPPER_CASE_REGEX = /(?=.*[A-Z])/;
export const LOWER_CASE_REGEX = /(?=.*[a-z])/;
