import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

import { SENTRY_DSN } from '../constants';

export const useSentry = (app: App<Element>, router: Router): void => {
  if (!SENTRY_DSN) {
    return;
  }

  const isDevelopment = import.meta.env.MODE === 'development';
  const integrations = [
    Sentry.httpClientIntegration(),
    Sentry.captureConsoleIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration({ router }),
  ];

  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    sampleRate: 1,
    tracesSampleRate: 0.5,
    tracePropagationTargets: [/^https:\/\/.+\.along\.pw/],
    integrations: isDevelopment ? [] : integrations,
    environment: import.meta.env.MODE,
    debug: isDevelopment,
    initialScope: {
      tags: { application: 'your-warehouse-b2b' },
    },
  });
};
