<template>
  <article :class="[styles.badge, styles[status], styles[size]]">
    <Typography as="span" :variant="typographyVariant">
      <slot name="default">
        {{ badgeTitle }}
      </slot>
    </Typography>

    <slot v-if="$slots['right-icon']" name="right-icon" />
  </article>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { BookingStatusType, type BookingStatus } from 'features/booking';
import { Typography, type TypographyVariant } from 'shared/ui';

import styles from './styles.module.css';

interface RentalStatusProps {
  status?: BookingStatus;
  size?: 's' | 'm';
}

defineSlots<{
  default(props: object): never;
  'right-icon'(props: object): never;
}>();

const props = withDefaults(defineProps<RentalStatusProps>(), {
  status: BookingStatusType.free,
  size: 'm',
});

const titleToBadgeStatus: Record<BookingStatus, string> = {
  free: 'Свободен',
  booked: 'Забронирован',
  rented: 'В аренде',
  extended: 'Продлён',
  archived: 'Закрыт',
};
const badgeTitle = computed(() => titleToBadgeStatus[props.status]);

const sizeToTypography: Record<Required<RentalStatusProps>['size'], TypographyVariant> = {
  s: 'text-xs-2',
  m: 'text-s-2',
};
const typographyVariant = computed<TypographyVariant>(() => sizeToTypography[props.size]);
</script>
