import type { components } from 'shared/api/schema';

export type ErrorMessage = components['schemas']['ErrorMessage'];

export type BookingBox = components['schemas']['BoxInfo'];
export type BookingStatus = components['schemas']['BookingStatus'];
export type BookingCalculationData = components['schemas']['CalculationData'];
export type BookingCalculationInfo = components['schemas']['CalculatedBoxInfo'];
export type BookingPeriodLength = components['schemas']['PeriodLength'];
export type BookingCreate = components['schemas']['BookingCreate'];
export type BookingRead = components['schemas']['BookingRead'];

export enum BookingStatusType {
  free = 'free',
  booked = 'booked',
  rented = 'rented',
  extended = 'extended',
  archived = 'archived',
}
