import { array, number, object, string } from 'yup';

import type { DiscountPromo, DiscountPromoCode } from 'features/discounts';

export const bookingTariffSchema = object({
  period: array().default([3]),
  adpromo: object<DiscountPromo>()
    .shape({
      id: string().nullable(),
      title: string().min(1, 'Введите данные').required('Введите данные'),
      discount_percentage: number(),
    })
    .default(undefined),
  promocode: object<DiscountPromoCode>()
    .shape({
      id: string().nullable(),
      title: string().min(1, 'Введите данные').required('Введите данные'),
      discount_value: number(),
    })
    .default(undefined),
});
